import React, { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "../../../utils/hooks";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";

import * as styles from "./Menu.module.scss";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at calc(100vw - 40px) 40px)`,
    transition: {
      type: "spring",
      stiffness: 90,
      damping: 20,
      restDelta: 2,
    },
    // width: "100%",
  }),
  closed: {
    clipPath: "circle(30px at calc(100vw - 40px) 40px)",
    transition: {
      delay: 0.2,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
    // width: "80px",
  },
};

const Menu = ({ items }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <motion.nav
      className={styles.nav}
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
    >
      <motion.div className={styles.background} variants={sidebar} />
      <Navigation items={items} toggle={() => toggleOpen()} />
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
};

export { Menu };
