import * as React from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import * as styles from "./Menu.module.scss";

const variants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    x: -50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

export const MenuItem = ({ item, toggle }) => {
  const style = { border: `2px solid ${colors[item]}` };
  return (
    <motion.li
      className={styles.menuItem}
      variants={variants}
      // whileHover={{ scale: 1.1 }}
      // whileTap={{ scale: 0.95 }}
    >
      <Link
        to={`/${item.to}`}
        className={styles.menuLink}
        style={style}
        onClick={toggle}
      >
        {item.label}
      </Link>
    </motion.li>
  );
};
