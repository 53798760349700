import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import { colorModes } from "../../../utils/common";
import { Context } from "../../../context/Context";
import { Menu } from "../Menu";

import * as styles from "./Header.module.scss";

const Header = ({ className, location }) => {
  const { colorMode, setColorMode } = useContext(Context);

  // useEffect(() => {
  //   setColorMode(colorModes[1]);
  // }, []);

  const link = location.pathname.split("/")[1];

  // const cls = classNames({ [styles.darkMode]: link });
  const cls = classNames({ [styles.lightMode]: link });

  const menuItems = [
    {
      to: "",
      label: "Learn",
    },
    {
      to: "about",
      label: "About",
    },
  ];
  return (
    <header className={cls}>
      <h2 id="mainmenulabel" className="visually-hidden">
        Main Menus
      </h2>
      <Menu items={menuItems} />
      {/* <nav aria-labelledby="mainmenulabel" className={styles.nav}>
        <ul>
          <li className={styles.name}>
            <Link to="/" activeClassName="active">
              LEARN HTML and more...
            </Link>
          </li>
          <li>
            <Link to="/about" activeClassName="active">
              about
            </Link>
          </li>
          <li>
            <Link to="/about" activeClassName="active">
              about
            </Link>
          </li>
        </ul>
      </nav> */}
    </header>
  );
};

export { Header };
